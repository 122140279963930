
import Storyblok from '@made-people/centra-storyblok-nuxt-shared/lib/views/Storyblok'
import * as imgproxy from '@made-people/imgproxy'
import { mapActions, mapGetters } from 'vuex'

export default {
  ...Storyblok,
  data () {
    return {
      meta: {
        title: '',
        meta: [],
        canonical: '',
      },
      product: null,
      reviews: null
    }
  },
  head () {
    return {
      title: this.meta.title,
      meta: this.meta.meta,
    }
  },
  computed: {
    ...Storyblok.computed,
    ...mapGetters({
      currentLanguageCode: 'frontend/currentLanguageCode',
      currentCountryCode: 'frontend/currentCountryCode',
      market: 'frontend/market',
      generalSettings: 'storyblok/settings',
      getCentraProduct: 'centra-product/getProductById',
      getYotpoReviews: 'yotpo/getProductReviews',
    }),
    jsonLd () {
      return JSON.stringify(this.getJsonLd())
    },

    /**
     * Takes the storyblok page info and pass to child
     * Excludes content
     *
     * This method should be included in shared
     * Needed for blog pages
     * But probably useful for other things as well
    */
    pageMeta () {
      return {
        ...this.page,
        content: null,
        blog_author: this.page.content?.blog_author
      }
    }
  },
  async mounted () {
    const pagetype = this.page?.content?.pageType ?? 'other'
    this.gtm_trackPage(
      pagetype,
      this.meta.title,
    )

    // for landing pages
    const landingPageProductDetails = this.page?.content?.productDetailsEventData?.[0]
    if (landingPageProductDetails?.enabled) {
      const centraProductId = landingPageProductDetails?.centraProductId
      if (!this.product) {
        await this.getProduct(centraProductId)
      }
      if (!this.reviews) {
        await this.loadYotpoReviews({ sku: this.product?.productSku })
        this.reviews = await this.getYotpoReviews(this.product?.productSku)
      }
      if (this.product) {
        this.gtm_trackProductDetail(this.product)
      }
    }
    const hash = this.$route.hash // Get the hash from the URL
    if (hash) {
      const element = document.querySelector(hash)
      if (element) {
        // scroll to spesific element
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  },
  methods: {
    ...Storyblok.methods,
    ...mapActions({
      loadYotpoReviews: 'yotpo/loadProductReviews',
    }),
    getJsonLd () {
      try {
        const product = this.product
        const reviews = this.reviews
        const aggregateRating = {}
        const reviewCount = reviews?.bottomline?.total_review || 0
        if (reviewCount) {
          const ratingValueDec = Math.round(
            reviews.bottomline.average_score * 10
          )
          let ratingValue = ratingValueDec / 10
          if (ratingValueDec % 10 === 0) {
            // Even rating values should not have decimals at all
            ratingValue = Math.abs(ratingValue)
          }
          aggregateRating.aggregateRating = {
            '@type': 'AggregateRating',
            ratingValue,
            reviewCount: reviews.bottomline.total_review,
          }
        }
        if (product) {
          const availability = product.inStock
            ? 'https://schema.org/InStock'
            : 'https://schema.org/OutOfStock'
          const currency =
            this.$store.getters['frontend/pricelist'].currency.currency
          return {
            '@context': 'https://schema.org/',
            '@type': 'Product',
            name: product.name,
            color: product.silkVariantName,
            material: product.google_merchant_material,
            image: [
              this.getImageUrl(
                product.mainImage,
                'extend:1/resize:fit/width:1080/quality:70'
              ),
            ],
            description: product.description.replace(/<br\/>|<br \/>|\n/g, ''),
            sku: product.sku,
            brand: {
              '@type': 'Brand',
              name: this.$nuxt.$options.head.title
            },
            offers: {
              '@type': 'Offer',
              availability,
              price: product.priceAsNumber,
              priceCurrency: currency,
              url: 'https://www.' + this.market.name + this.$u(product.url),
              itemCondition: 'NewCondition',
            },
            ...aggregateRating,
          }
        }
      } catch (e) {
        console.error('Failed to generate product ld+json', e)
      }
      return null
    },
    getImageUrl (image, def) {
      imgproxy.useBaseUrl(this.$config.imageProxyUrl)
      return imgproxy.transform(image, def)
    },
    async getProduct (id) {
      this.error = false
      let centraProduct = await this.getCentraProduct(id ?? this.productId)

      if (!centraProduct) {
        await this.$store.dispatch('centra-product/lookupProducts', [id ?? this.productId])
        centraProduct = await this.getCentraProduct(id ?? this.productId)

        if (!centraProduct) {
          console.error('Product not found')
          this.error = true
          return
        }
      }

      this.product = centraProduct
    },
    setMeta () {
      if (this.page && this.page.content.seo) {
        const blok = this.page.content
        const description = blok.seo.description || this.generalSettings.defaultMetaDescription
        const canonical = 'https://www.' + this.market.name + (blok.canonical ? this.$u(blok.canonical) : this.$route.path)
        const meta = [
          {
            hid: 'description',
            name: 'description',
            content: description
          },
          {
            hid: 'og:title',
            property: 'og:title',
            content: blok.seo.og_title || blok.seo.title || this.page.name
          },
          {
            hid: 'og:description',
            property: 'og:description',
            content: blok.seo.og_description || description
          },
          { hid: 'og:url', property: 'og:url', content: canonical },
          { hid: 'og:type', property: 'og:type', content: 'website' },
          {
            hid: 'og:site_name',
            property: 'og:site_name',
            content: this.$nuxt.$options.head.title
          }
        ]

        const robots = blok.metaRobots || null
        if (robots && robots.length) {
          meta.push({
            hid: 'robots',
            name: 'robots',
            content: robots.join(',')
          })
        }

        const ogImage = blok.seo.og_image || this.generalSettings?.openGraphImage?.filename || null
        if (ogImage) {
          const resizedImage = this.$imgproxy.transform(ogImage, 'rs:fill/w:1200/h:630/g:sm/q:70')
          meta.push({
            hid: 'og:image',
            content: resizedImage,
            property: 'og:image'
          })
        }

        const metaTitlePrefix = this.generalSettings?.metaTitlePrefix !== '' ? this.generalSettings?.metaTitlePrefix + ' ' : ''
        const metaTitleSuffix = this.generalSettings?.metaTitleSuffix !== '' ? ' ' + this.generalSettings?.metaTitleSuffix : ''
        const metaTitle = blok.seo.title || this.page.name
        this.meta.title = metaTitlePrefix + metaTitle + metaTitleSuffix
        this.meta.meta = meta
      }
    },
  }
}
